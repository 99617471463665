<template>
  <div class="animated fadeIn">
    <CardTable :title="title"
               ref="EmailReminder"
               :columns="table.columns"
               :rows="table.rows"
               :isLoading="table.isLoading"
               :pageSize="serverParams.pageSize"
               :pageNumber="serverParams.pageNumber"
               :totalPage="table.totalPage"
               :totalRecords="table.totalRecords"
               :selectMode="selectMode"
               @onRowSelected="onRowSelected"
               @onPageChange="onPageChange">
      <template #action>
        <div class="card-header-actions">
          <b-row class="d-flex flex-row-reverse">
            <b-col sm="3">
              <BuildingDropdown :server-params="serverParams"
                                :on-select="getAll" />
            </b-col>
          </b-row>
          <b-button size="sm" variant="primary" class="mr-2" @click="genratePdf">
            Download Invoices
          </b-button>

          <b-button size="sm" variant="primary" class="mr-2" @click="sendReminder('1')">
            Send 1st Reminder
          </b-button>

          <b-button size="sm" variant="primary" class="mr-2" @click="sendReminder('2')">
            Send 2nd Reminder
          </b-button>


        </div>
      </template>

      <template #head(selected)="data">
        <b-form-checkbox v-model="selectAllCheckBox" @change="selectAll">
        </b-form-checkbox>
      </template>

      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>

    </CardTable>
  </div>
</template>

<script>
  import { EmailReminder as columns } from "@/shared/columns";
  import BuildingDropdown from "@/components/common/BuildingDropdown";
  export default {


    data: () => ({
      selectAllCheckBox: false,
      selectedRows: [],
      selectMode: 'multi',
      title: "Email Reminder",
      serverParams: {
        pageNumber: 1,
        pageSize: 10000,
        keyword: null,
      },
      table: {
        isLoading: false,
        columns,
        rows: [],
        totalPage: 0,
        totalRecords: 0,
      },
    }),
    created() {
      const self = this;
      self.getAll();
    },
    components: {
      BuildingDropdown,
    },
    methods: {
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      onRowSelected(items) {
        this.selectedRows = items
      },
      onPageChange(params) {
        this.updateParams({ pageNumber: params });
        this.getAll();
      },
      getAll() {
        const self = this;

        self.table.isLoading = true;
        self.$store
          .dispatch("apis/get", {
            url: "/transaction/reminder",
            params: self.serverParams,
          })
          .then((response) => {

            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.table.rows = response.data.data;
              self.table.totalPage = response.data.totalPage;
              self.table.totalRecords = response.data.totalData;
            }
            self.table.isLoading = false;
          });
      },
      sendReminder(params) {
        const self = this;

        let _confirmText = "You are about to send Email Reminder. Are you sure ?",
            _okText = "Yes, Submit",
            _action = "apis/post",
          _url = "/transaction/reminder";
        //let ids = this.selectedRows.map(x => x.account.accountId).join(", ");
        let ids = this.selectedRows.map(x => x.account.accountId);

        let data = {
          Accounts: ids,
          ReminderNumber: parseInt(params) 

        }
        self.$dialog
          .confirm(_confirmText, {
            okText: _okText,
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch(_action, {
                url: _url,
                params: data,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  this.$refs.EmailReminder.clearSelected();
                }
              });
          });

      },
      genratePdf() {
        const self = this;

        let _confirmText = "Are you sure you want to Download. Are you sure ?",
          _okText = "Yes, Submit",
          _action = "apis/download",
          _url = "/transaction/reminder/invoices";
        let ids = this.selectedRows.map(x => x.account.accountId);

        let data = {
          Accounts: ids,
          ReminderNumber: 1
        }
        self.$dialog
          .confirm(_confirmText, {
            okText: _okText,
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch(_action, {
                url: _url,
                params: data,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {

                  let fileURL = window.URL.createObjectURL(new Blob([response])),
                    fileLink = document.createElement("a");

                  fileLink.href = fileURL;
                  fileLink.setAttribute("download","reminder-invoices.pdf");
                  document.body.appendChild(fileLink);

                  fileLink.click();
                }
              });
          });
      },
      selectAll() {
        if (this.selectAllCheckBox) {
          this.$refs.EmailReminder.selectAllRows();
        } else {
          this.$refs.EmailReminder.clearSelected();
        }
      },
    },
  };
</script>
